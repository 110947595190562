<template>
  <div>
    <v-container
      class="default-background mb-12"
      fluid
      style="min-height: 93vh"
    >
      <v-row>
        <SideNav />
        <v-col lg="9" class="mb-5">
          <div :class="$vuetify.breakpoint.mdAndDown ? '' : 'pl-3'">
            <v-fade-transition mode="out-in">
              <div
                v-if="
                  !(
                    ($route.path !== '/workplace') ^
                    ($route.path !== '/classroom')
                  )
                "
              ></div>
            </v-fade-transition>
            <v-fade-transition mode="out-in">
              <router-view></router-view>
            </v-fade-transition>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="pa-0" absolute>
      <Footer />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Main",
  components: {
    SideNav: () => import("@/components/SideNav"),
    Footer: () => import("@/components/Footer"),
  },
  computed: {
    pageTitle() {
      if (this.$route.name) return this.$route.name;
      else {
        let title = this.$route.params.slug;
        return title.charAt(0).toUpperCase() + title.slice(1);
      }
    },
    accessToken: function () {
      return this.$store.state.authModule.accessToken;
    }
  },
  watch: {
    accessToken: {
      async handler(newAccessToken) {
        if (newAccessToken) {
          await this.$store.dispatch('authModule/getUser');
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('authModule/saveAccessToken');
  },
};
</script>

<style></style>
